const VideoBackgroundControls = (($) => {
    class VideoBackground {
        constructor(node) {
            this.node = node;
            this.$node = $(this.node);
            this.$video = $(this.$node.data('video-actions'));
        }

        toggleVideo(event) {
            if (this.$video[0].paused == true) {
                this.$video[0].play();
                this.$node.addClass('active');
              } else {
                this.$video[0].pause();
                this.$node.removeClass('active');
              }
        }

        init() {
            this.$node.on('click', this.toggleVideo.bind(this))
        }
    }

    return {
        init({ selector } = {}) {
            return jQuery(selector).map((index, node) => {
                const module = new VideoBackground(node);
                module.init();
            });
        },
    };
})(window.jQuery);

(($) => {
    let context = $(document);

    VideoBackgroundControls.init({
        selector: "[data-video-actions]",
    });
})(window.jQuery);
