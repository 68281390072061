const Toggle = (($) => {
    class Toggle {
        constructor(node) {
            this.node = node;
            this.$node = $(node);

            const config = this.$node.data("toggle");

            const defaultConfig = {
                target: false,
                slideToggle: false,
                targetSlideToggle: false,
                activeClass: "active",
                slideSpeed: 400,
                closeOnBlur: false
            };

            this.config = { ...defaultConfig, ...config };
        }

        toggleActive(event) {
            this.$node.toggleClass(this.config.activeClass);

            if (this.config.slideToggle) {
                this.$node.slideToggle(this.config.slideSpeed);
            }

            $(this.config.target).toggleClass(this.config.activeClass);

            if (this.config.targetSlideToggle) {
                $(this.config.target).slideToggle(this.config.slideSpeed);
            }
        }

        onBlur(event) {
            let $target = $(event.target);
            if(!$target.closest(this.config.target).length) {
                this.$node.removeClass(this.config.activeClass);

                if (this.config.slideToggle) {
                    this.$node.slideUp(this.config.slideSpeed);
                }
    
                $(this.config.target).removeClass(this.config.activeClass);
    
                if (this.config.targetSlideToggle) {
                    $(this.config.target).slideUp(this.config.slideSpeed);
                }
            }
        }

        init() {
            this.$node.on("click", this.toggleActive.bind(this));
            if(this.config.closeOnBlur) {
                $(document).on('click', this.onBlur.bind(this));
            }
        }
    }

    return {
        init({ selector } = {}) {
            return jQuery(selector).map((index, node) => {
                const module = new Toggle(node);
                module.init();
            });
        },
    };
})(window.jQuery);

(($) => {
    let context = $(document);

    Toggle.init({
        selector: "[data-toggle]",
    });
})(window.jQuery);
