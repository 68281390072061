import { formSkipEmptyParams } from "./form-utils";

const Filters = (($) => {
    class Filters {
        constructor(node) {
            this.node = node;
            this.$node = $(this.node);

        }

        init() {
            formSkipEmptyParams(this.node);
        }
    }

    return {
        init({ selector } = {}) {
            return jQuery(selector).map((index, node) => {
                const module = new Filters(node);
                module.init();
            });
        },
    };
})(window.jQuery);

(($) => {
    let context = $(document);

    Filters.init({
        selector: "[data-filters]",
    });
})(window.jQuery);
