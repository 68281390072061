class ContentRevealer {
    constructor(detailsButton, contentToReveal, svgIcon, storageKey) {
        this.detailsButton = detailsButton;
        this.contentToReveal = contentToReveal;
        this.svgIcon = svgIcon;
        this.isRevealed = false;
        this.storageKey = storageKey;

        this.addEventListeners();
        this.loadState();
    }

    addEventListeners() {
        this.detailsButton.addEventListener('click', this.toggleContent.bind(this));
    }

    toggleContent(event) {
        event.preventDefault();
        if (!this.isRevealed) {
            this.contentToReveal.style.transform = 'translateY(0%)';
            this.svgIcon.classList.add('details-open');
        } else {
            this.contentToReveal.style.transform = 'translateY(calc(100% - 60px))';
            this.svgIcon.classList.remove('details-open');
        }
        this.isRevealed = !this.isRevealed;
        this.saveState();
    }

    saveState() {
        localStorage.setItem(this.storageKey, this.isRevealed ? '1' : '0');
    }

    loadState() {
        const state = localStorage.getItem(this.storageKey);
        if (state === '1') {
            this.isRevealed = true;
            this.contentToReveal.style.transform = 'translateY(0%)';
            this.svgIcon.classList.add('details-open');
        }
    }
}

document.addEventListener('DOMContentLoaded', function() {
    const detailsButtons = document.querySelectorAll('.details-toggle');
    const contentToReveals = document.querySelectorAll('.partner-wrap-mobile');
    const svgIcons = document.querySelectorAll('.details-toggle .svg-icon');

    const contentRevealers = [];

    detailsButtons.forEach((detailsButton, index) => {
        const storageKey = `contentRevealer_${index}`;
        const partnerWrap = detailsButton.closest('.partner-wrap');
        contentRevealers.push(new ContentRevealer(detailsButton, contentToReveals[index], svgIcons[index], storageKey));

        // If the partner-wrap content should stay closed by default, add the following check:
        // if (!localStorage.getItem(storageKey)) {
        //     contentRevealers[index].toggleContent({ preventDefault: () => {} });
        // }
    });
});