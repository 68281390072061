const meltwater = (($) => {
    const booleanKeys = [
        'accessibility',
        'adaptiveHeight',
        'autoplay',
        'arrows',
        'centerMode',
        'dots',
        'draggable',
        'fade',
        'fadeOnSelect',
        'infinite',
        'mobileFirst',
        'pauseOnFocus',
        'pauseOnHover',
        'pauseOnDotsHover',
        'swipeToSlide',
        'touchMove',
        'variableWidth',
        'vertical',
        'verticalSwiping',
        'rtl',
        'waitForAnimate',
    ];

    function normalizeConfig(config) {
        Object.keys(config).forEach((key)=>{
          config[key] = (booleanKeys.includes(key)) ? Boolean(config[key]) : config[key];
        });
        return config;
    }

    class meltwater {
        constructor(node) {
            this.node = node;
            this.$node = $(node);
            this.config = this.$node.data('meltwater');
            this.endpoint = 'https://app.meltwater.com/gyda/outputs/5ea2834d98975f4e160c2d16/rendering?apiKey=5e1cbe134f485900183e0874&type=xml';

            if (this.config) {
                this.config = normalizeConfig(this.config);
            }

            this.defaultConfig = {
                prevArrow: `<button class="p-4 mr-4 transition bg-green-200 border-0 cursor-pointer pointer-events-auto hover:bg-green slick-prev relative z-10 slick-arrow"  type="button" style=""><svg class="svg-icon" style="width: 30px; height: 30px;" aria-hidden="true" role="img" focusable="false" viewBox="0 0 13.8 29.9"> <path d="M0,14.9L13.8,0v29.9L0,14.9z" fill="currentColor"></path> </svg></button>`,
                nextArrow: `<button class="p-4 ml-4 transition bg-green-200 border-0 cursor-pointer pointer-events-auto hover:bg-green slick-next relative z-10 slick-arrow"  type="button" style=""> <svg class="svg-icon" style="width: 30px; height: 30px;" aria-hidden="true" role="img" focusable="false" viewBox="0 0 13.804 29.9"> <path d="M0,0H29.9L15.011,13.8Z" transform="translate(0 29.9) rotate(-90)" fill="currentColor"></path> </svg></button>`
            }

            this.config = Object.assign(this.defaultConfig, this.config);

        }

        render(documents) {
            if(documents.length) {
                const documentsHtml = documents.reduce((html, document) => {
                    const title = document.getElementsByTagName('title')[0].textContent;
                    const url = document.getElementsByTagName('url')[0].textContent;
                    const source = document.getElementsByTagName('sourcename')[0].textContent;

                    return html + `<div class="px-8 py-6 leading-snug bg-white mx-2"><a class="font-semibold underline decoration-2 underline-offset-4" href="${url}" target="_blank">${title}</a><div class="gray-200 italic mt-2">${source}</div></div>`;
                }, '');

                this.$node.html(documentsHtml).slick(this.config);
            }
        }

        fetchData() {
            fetch(this.endpoint, {
                headers: {
                  'Content-Type': 'application/xml'
                },
                responseType: 'text'
            })
            .then(response => response.text())
            .then(data => {
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(data, 'application/xml');
                const documents = Array.from(xmlDoc.getElementsByTagName('document'));
                this.render(documents)
            })
            .catch(error => {
                console.error('Error fetching meltwater data:', error);
            });
        }

        init() {
            this.fetchData();
        }
    }

    return {
        init({ selector } = {}) {
            return jQuery(selector).map((index, node) => {
                const module = new meltwater(node);
                module.init();
            });
        },
    };
})(window.jQuery);

(($) => {
    let context = $(document);

    meltwater.init({
        selector: "[data-meltwater]",
    });
})(window.jQuery);
