const Anchor = (($) => {
  class Anchor {
    constructor() {
      this.$window = $(window);
      this.$document = $(document);
      this.$body = $("body");
      this.$htmlBody = $("html,body");

      this.$anchor = $('a[href*="#"]:not([href="#"]):not([href="#0"])');
      this.$header = $("header");
      this.$adminbar = $("#wpadminbar");
    }

    windowHash() {
      var windowHash = window.location.hash;

      if ($(windowHash).length) {
        this.skipToContent(windowHash);
      }
    }

    skipToContent(currentTarget) {
      const headerHeight = this.$header.outerHeight();
      const wpadminbarHeight =
        this.$adminbar.length != 0 ? this.$adminbar.outerHeight() : 0;

      var pushContent = headerHeight + wpadminbarHeight + 0;

      if ($(currentTarget).length) {
        this.$htmlBody.animate(
          { scrollTop: $(currentTarget).offset().top - pushContent },
          200
        );
      }
    }

    anchorSkipToContent(event, context) {
      const headerHeight = this.$header.outerHeight();
      const wpadminbarHeight =
        this.$adminbar.length != 0 ? this.$adminbar.outerHeight() : 0;

      var pushContent = headerHeight + wpadminbarHeight + 0;

      if (
        location.pathname.replace(/^\//, "") ==
          event.currentTarget.pathname.replace(/^\//, "") &&
        location.hostname == event.currentTarget.hostname
      ) {
        // Figure out element to scroll to
        var target = jQuery(event.currentTarget.hash);

        target = target.length
          ? target
          : jQuery("[name=" + event.currentTarget.hash.slice(1) + "]");
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          jQuery("html, body").animate(
            {
              scrollTop: target.offset().top - pushContent,
            },
            800,
            function () {
              // Callback after animation
              // Must change focus!
              var $target = jQuery(target);
              $target.focus();
              if ($target.is(":focus")) {
                // Checking if the target was focused
                return false;
              } else {
                $target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              }
            }
          );
        }
      }
    }

    setEventBindings() {
      this.$anchor.on("click", (event) => {
        this.anchorSkipToContent(event, this);
      });
      this.$window.on("load hashchange", this.windowHash.bind(this));
    }

    init() {
      this.setEventBindings();
    }
  }

  return {
    init() {
      const module = new Anchor();
      module.init();
    },
  };
})(window.jQuery);

(($) => {
  let context = $(document);

  Anchor.init();
})(window.jQuery);
