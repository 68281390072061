const $ = window.jQuery;

export const formSkipEmptyParams = (element) => {
  element.addEventListener('formdata', (event) => {
    let formData = event.formData;
    for (let [name, value] of Array.from(formData.entries())) {
      if (value === '' || value === 'on') formData.delete(name);
    }
  });

}

export const toggleOther = (element, otherElement, toggleValue) => {
  $(element).on('change', (event) => {
    const value = $(event.target).val();
    if(value === toggleValue) {
      $(otherElement).show();
    } else {
      resetValue($(otherElement));
      resetValue($(otherElement).find('input, select, textarea'));
      $(otherElement).hide();
    }
  })
}

export const resetValue = ( element ) => {
  const $element = $(element);
  element.each(( index, item ) => {
    const tagName = item.tagName;

    if(tagName == 'INPUT') {
      const type = item.type;
      switch(type) {
        case 'checkbox':
        case 'radio':
          item.checked = false;
          break;
        default:
          item.value = ""
      }
    }

    if(tagName === 'SELECT') {
      item.selectedIndex = 0;
    }

    if(tagName === 'TEXTAREA') {
      item.value = "";
    }
  });
}