import { formSkipEmptyParams, toggleOther } from "./form-utils";

const SelectInput = (($) => {
    class SelectInput {
        constructor(node) {
            this.node = node;
            this.$node = $(this.node);
            this.fieldName = this.$node.closest('[data-field]').data('field');
            this.$select = this.$node.closest('form').find('[name="' + this.fieldName + '"');
        }

        onSelectChange(event) {
            var selectedIndex = event.target.selectedIndex;
            var label = event.target.options[selectedIndex].label;
            this.$node.html(label);
        }

        init() {
            this.$select.on('change', this.onSelectChange.bind(this));
        }
    }

    return {
        init({ selector } = {}) {
            return jQuery(selector).map((index, node) => {
                const module = new SelectInput(node);
                module.init();
            });
        },
    };
})(window.jQuery);

(($) => {
    let context = $(document);

    SelectInput.init({
        selector: "[data-select-label]",
    });
})(window.jQuery);
